<template>
  <div>
    <el-button
      v-if="!slotType"
      type="primary"
      size="small"
      :disabled="disabled"
      plain
      @click="invitation(item)"
      >{{ $t("meeting1v1") }}</el-button
    >
    <!-- {{ $t("Make_Appointment") }} -->
    <span @click="invitation(item)" v-else><slot name="chatBtn"></slot></span>
    <dailyChatOrder
      v-if="orderVisible"
      @orderVisibleShow="orderVisibleShow"
      :orderVisible="orderVisible"
      :item="itemObj"
    ></dailyChatOrder>
    <el-dialog
      v-if="chat1v1Show"
      :title="$t('meeting1v1')"
      :visible.sync="chat1v1Show"
      width="1100px"
    >
      <userChat :miniInfo="chatInfo" ref="userChat"></userChat>
    </el-dialog>
  </div>
</template>
<script>
import dailyChatOrder from "~/baseComponents/dailyChatOrder";
import userChat from "~/baseComponents/chat/userChat";
import { handleToVideoChatPageOld } from "~/baseUtils";

export default {
  props: {
    oToId: {
      type: Number || String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    btnHide: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      orderVisible: false,
      chat1v1Show: false,
      chatInfo: {},
      itemObj: {},
      slotType: false,
    };
  },
  watch: {
    chat1v1Show() {
      if (!this.chat1v1Show) {
        this.$refs.userChat.closeVideo();
      }
    },
  },
  components: { dailyChatOrder, userChat },
  methods: {
    invitation(val) {
      console.log(val);
      if (this.disabled) {
        return;
      }

      this.handleUserMeetingStatus({
        notLogin: () => {
          //
          this.$router.push("/sign");
        },
        notEnroll: () => {
          this.$store.state.isApplyShow = true;
          this.$GLOBALEVENT.$emit("ISSHOWENROLL");
        },
        enrollWait: () => {
          this.$message.warning(this.$t("exp_not_shenhe"));
        },
        enrollFail: () => {
          this.$message.warning(this.$t("exp_not_tongguo"));
        },
        enrollComplete: () => {
          // if (val.id) {
          //   this.$log({
          //     type: 1,
          //     id: "Hall.business.booth.follow",
          //     topic: "Hall.business",
          //     content: JSON.stringify({
          //       user_id: val.id,
          //     }),
          //   });
          // }
          // if (val.user_id) {
          //   this.$log({
          //     type: 1,
          //     id: "overseas.appointment",
          //     topic: "overseas",
          //     content: JSON.stringify({
          //       user_id: val.user_id,
          //     }),
          //   });
          // }

          this.isOnlineChage(val);
        },
      });
    },
    async isOnlineChage(row) {
      var status = null;
      let params = {
        meeting_id: this.MEETING_ID,
        one2one_id: this.oToId,
      };
      if (row.user_id) {
        params.user_id = row.user_id;
      } else {
        params.user_id = row.id;
      }
      let data = await this.$store.dispatch("baseStore/getIsOnline", params);
      if (data.success) {
        status = data.data;
      }
      if (status == 1) {
        // if (this.LOCALE == "zh") {
          this.chat1v1Show = true;
          this.$nextTick((e) => {
            this.chatInfo = {
              user_id: params.user_id,
              live_id: row.live_id,
            };
          });
        // } else {
        //   handleToVideoChatPageOld(row.live_id, this.USER_INFO.id, "free");
        // }
      } else {
        let companyId = this.$route.query.company_id;
        this.orderVisible = true;
        this.itemObj = {
          company_id: companyId || row.company_id,
        };
        if (row.user_id) {
          this.itemObj.user_id = row.user_id;
        } else {
          this.itemObj.user_id = row.id;
        }
      }
    },
    // 预约洽谈弹框的显示与隐藏
    orderVisibleShow(value) {
      this.orderVisible = false;
    },
  },
  mounted() {
    this.slotType = this.$scopedSlots.$hasNormal;
  },
};
</script>
<style lang="less" scoped>
</style>