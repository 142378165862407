<template>
  <div>
    <div v-if="PJSource ===10">
      <div class="ecode tc" v-if="LOCALE == 'zh'">
      <el-image :src="img_url" class="img"></el-image>
      <div class="textPage">
        扫码进入<span class="mainColor">2021物博会小程序</span>，
        进入移动端查看企业展台
      </div>
    </div>
    </div>
    <div v-if="PJSource === 11">
      <div class="ecode tc" v-if="LOCALE == 'zh'">
      <el-image :src="img_url1" class="img"></el-image>
      <div class="textPage">
        扫码进入<span class="mainColor">商交会小程序</span>，
        进入移动端查看企业展台
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import boothMixin from "./mixin";
export default {
  mixins: [boothMixin],
  data() {
    return {
      img_url: "",
      img_url1:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/fuda/fudaappimg.jpg"
    };
  },
 async mounted() {
    let companyId = this.$route.query.company_id;
    let boothId = this.$route.query.booth_id;
     let data = await this.$store.dispatch("baseStore/getMiniEcode", {
        page: 'pages/sharePage/index',
        width: 300,
        scene: `4&${companyId}&${boothId}`,
        source: this.PJSource
      });
      this.initComplete();
      if(data.success){
          this.img_url = data.file
      }
  },
};
</script>

<style scoped lang="less">
.ecode {
  width: 341px;
  height: 300px;
  background: #fff;
  font-size: 16px;
  margin-bottom: 12px;
  .img {
    width: 194px;
    margin-top: 10px;
  }
  .textPage {
    width: 220px;
    margin: 8px auto;
    line-height: 25px;
  }
}
</style>