import { render, staticRenderFns } from "./1v1ChatBtn.vue?vue&type=template&id=ee02fdda&scoped=true&"
import script from "./1v1ChatBtn.vue?vue&type=script&lang=js&"
export * from "./1v1ChatBtn.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee02fdda",
  null
  
)

export default component.exports