import { render, staticRenderFns } from "./dailyChatOrder.vue?vue&type=template&id=57593e5d&scoped=true&"
import script from "./dailyChatOrder.vue?vue&type=script&lang=js&"
export * from "./dailyChatOrder.vue?vue&type=script&lang=js&"
import style0 from "./dailyChatOrder.vue?vue&type=style&index=0&id=57593e5d&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57593e5d",
  null
  
)

export default component.exports