<template>
  <div class="marBottom">
    <div class="videoAndCompany" v-if="videoList.length">
      <div class="video">
        <div class="topBox">
          <div class="liveMsg liveThumb" :class="{ isPlay: isPlay }">
            <plLivePlay
              @liveStart="liveStart"
              @liveEnd="liveEnd"
              :channelId="liveMsg.channelId"
              :showJoinBtn="true"
            />
          </div>
          <AliyunVideo
            v-if="!isPlay"
            ref="AliyunVideo"
            class="liveThumb"
            @videoEnd="videoEnd"
            :AliyunId="playAliyunId"
          />
        </div>
        <div class="swiperBox">
          <div class="swiper-container">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in videoList"
                :key="index"
                @click="videoPlayChange(index, item.aliyun_id)"
              >
                <div class="meak">
                  <i class="el-icon-video-play"></i>
                </div>
                <el-image
                  class="coverImg"
                  :src="item.video_cover_url"
                  :fit="'cover'"
                ></el-image>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="company">
        <div class="companyName">
          <div class="name">
            <span
              class="namaTxt"
              :title="
                companyInfo.name_en | priorFormat(companyInfo.name_zh, LOCALE)
              "
              >{{
                companyInfo.name_en | priorFormat(companyInfo.name_zh, LOCALE)
              }}</span
            >
            <span class="boothId"
              >{{ $t("Booth") }}：{{ companyInfo.booth_no | textFormat }}</span
            >
          </div>
          <div class="loveBtn">
            <el-button
              v-if="collectionStatus == 0"
              type="warning"
              @click="collection(1)"
              size="small"
              icon="el-icon-collection-tag"
              >{{ $t("enshrine") }}</el-button
            >
            <el-button
              type="info"
              size="small"
              @click="collection(0)"
              v-if="collectionStatus == 1"
              >{{ $t("Collected") }}</el-button
            >
          </div>
        </div>
        <div class="other">
          <p>
            {{
              companyInfo.country_id
                | countryCityFormat(
                  companyInfo.region_en,
                  companyInfo.region_zh,
                  LOCALE
                )
            }}
          </p>
          <p class="mar">
            {{ $t("companyIndustry") }}:{{
              companyInfo.industry | industryFormat(LOCALE)
            }}
          </p>
          <!-- <p>
            {{ $t("association") }}：<span
              style="padding-right: 15px"
              v-for="(item, index) in companyInfo.certificate"
              :key="index"
            >
              {{ item | ccccccccccccccccccc(LOCALE) }}</span
            >
          </p> -->
        </div>
        <div class="line">
          <span>{{ $t("BussinessMajor") }}</span>
          <span v-for="(item, index) in shipping_line.slice(0, 5)" :key="index"
            >#{{ item.name_en | priorFormat(item.name_cn, LOCALE) }}</span
          >
          <span v-if="shipping_line.length > 5">{{ $t("all") }}</span>
        </div>
        <div class="line">
          <span>{{ $t("busenessList") }}</span>
          <span
            v-for="(item, index) in business_classification.slice(0, 5)"
            :key="index"
            >#{{ item.desc_en | priorFormat(item.desc_zh, LOCALE) }}</span
          >
          <span v-if="business_classification.length > 5">{{ $t("all") }}</span>
        </div>
        <div class="lastMsg">
          <p>
            {{ $t("telephone") }}
            <span v-if="IS_LOGIN">
              ：{{ companyInfo.telephone | textFormat }}
              <i
                @click="copyText(companyInfo.telephone, 1)"
                class="el-icon-document-copy"
              ></i>
            </span>
            <span
              v-else
              style="color: #0083f6; cursor: pointer"
              @click="$router.push('/sign')"
              type="text"
              >{{ $t("yetLogin") }}</span
            >
          </p>
          <p class="mar">
            {{ $t("email") }}：<span v-if="IS_LOGIN">
              {{ companyInfo.email | textFormat }}
              <i
                @click="copyText(companyInfo.email, 2)"
                class="el-icon-document-copy"
              ></i
            ></span>
            <span
              v-else
              style="color: #0083f6; cursor: pointer"
              @click="$router.push('/sign')"
              type="text"
              >{{ $t("yetLogin") }}</span
            >
          </p>
        </div>

        <div class="lastMsg">
          <p class="companyAddress">
            {{ $t("companyAddress") }}：{{
              companyInfo.address_en
                | priorFormat(companyInfo.address_zh, LOCALE)
            }}
          </p>
        </div>
        <div class="users">
          <div class="userItem" v-for="(item, index) in userList" :key="index">
            <div class="userFl" @click="showCard(item)">
              <el-avatar
                style="width: 80px; height: 80px"
                :src="item.avatar ? item.avatar : DEFAULT_AVATAR"
              ></el-avatar>
              <p class="onLine" v-if="item.is_online == 1">
                <i></i> {{ $t("status_Online") }}
              </p>
              <p class="busy" v-else-if="item.is_online == 2">
                <i></i> {{ $t("status_Busy") }}
              </p>
              <p class="off" v-else><i></i> {{ $t("status_Offline") }}</p>
            </div>
            <div class="userFr">
              <p class="userName">
                {{ item.name_en | priorFormat(item.name_zh, LOCALE) }}
              </p>
              <p class="title">
                {{ item.job_title_en | priorFormat(item.job_title_zh, LOCALE) }}
              </p>
              <!-- <el-button type="primary" size="small" v-if="item.is_online == 1">{{
                $t("Negotiate_now")
              }}</el-button> -->
              <!-- <el-button
                type="primary"
                :disabled="isPreview"
                @click="invitation(item)"
                size="small"
                >{{ $t("meetingAppointment") }}</el-button
              > -->
              <chatBtn
                :oToId="item.one2one_id"
                :item="item"
                :disabled="isPreview"
              >
                <template slot="chatBtn">
                  <el-button
                    type="primary"
                    :disabled="isPreview"
                    size="small"
                    >{{ $t("meeting1v1") }}</el-button
                  >
                </template>
              </chatBtn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="companyInfo" v-else>
      <div class="logo">
        <el-image
          style="width: 100px; height: 100px"
          :src="
            companyInfo.company_logo ? companyInfo.company_logo : DEFAULT_LOGO
          "
          :fit="'cover'"
        ></el-image>
      </div>
      <div class="message">
        <div class="companyName">
          <div class="name">
            <span
              class="namaTxt"
              :title="
                companyInfo.name_en | priorFormat(companyInfo.name_zh, LOCALE)
              "
              >{{
                companyInfo.name_en | priorFormat(companyInfo.name_zh, LOCALE)
              }}</span
            >
            <span class="boothId"
              >{{ $t("Booth") }}：{{ companyInfo.booth_no | textFormat }}</span
            >
          </div>
          <div class="loveBtn" v-if="USER_INFO.company_id != companyId">
            <el-button
              v-if="collectionStatus == 0"
              type="warning"
              @click="collection(1)"
              size="small"
              icon="el-icon-collection-tag"
              >{{ $t("enshrine") }}</el-button
            >
            <el-button
              type="info"
              size="small"
              @click="collection(0)"
              v-if="collectionStatus == 1"
              >{{ $t("Collected") }}</el-button
            >
          </div>
        </div>
        <div class="other">
          <p>
            {{
              companyInfo.country_id
                | countryCityFormat(
                  companyInfo.region_en,
                  companyInfo.region_zh,
                  LOCALE
                )
            }}
          </p>
          <p class="mar">
            {{ $t("companyIndustry") }}:{{
              companyInfo.industry | industryFormat(LOCALE)
            }}
          </p>
          <!-- <p>
            {{ $t("association") }}：<span
              style="padding-right: 15px"
              v-for="(item, index) in companyInfo.certificate"
              :key="index"
            >
              {{ item | certificateItemFormat(LOCALE) }}</span
            >
          </p> -->
        </div>
        <div class="line">
          <span>{{ $t("BussinessMajor") }}</span>
          <span v-for="(item, index) in shipping_line.slice(0, 5)" :key="index"
            >#{{ item.name_en | priorFormat(item.name_cn, LOCALE) }}</span
          >
          <span v-if="shipping_line.length > 5">{{ $t("all") }}</span>
        </div>
        <div class="line">
          <span>{{ $t("busenessList") }}</span>
          <span
            v-for="(item, index) in business_classification.slice(0, 5)"
            :key="index"
            >#{{ item.desc_en | priorFormat(item.desc_zh, LOCALE) }}</span
          >
          <span v-if="business_classification.length > 5">{{ $t("all") }}</span>
        </div>
        <div class="lastMsg">
          <p>
            {{ $t("telephone") }}
            <span v-if="IS_LOGIN">
              ：{{ companyInfo.telephone | textFormat }}
              <i
                @click="copyText(companyInfo.telephone, 1)"
                class="el-icon-document-copy"
              ></i>
            </span>
            <span
              v-else
              style="color: #0083f6; cursor: pointer"
              @click="$router.push('/sign')"
              type="text"
              >{{ $t("yetLogin") }}</span
            >
          </p>
          <p class="mar">
            {{ $t("email") }}：<span v-if="IS_LOGIN">
              {{ companyInfo.email | textFormat }}
              <i
                @click="copyText(companyInfo.email, 2)"
                class="el-icon-document-copy"
              ></i
            ></span>
            <span
              v-else
              style="color: #0083f6; cursor: pointer"
              @click="$router.push('/sign')"
              type="text"
              >{{ $t("yetLogin") }}</span
            >
          </p>
        </div>
        <div class="lastMsg">
          <p class="companyAddress">
            {{ $t("companyAddress") }}：{{
              companyInfo.address_en
                | priorFormat(companyInfo.address_zh, LOCALE)
            }}
          </p>
        </div>
        <div class="users" v-if="userList.length">
          <div class="userItem" v-for="(item, index) in userList" :key="index">
            <div class="userFl" @click="showCard(item)">
              <el-avatar
                style="width: 80px; height: 80px"
                :src="item.avatar ? item.avatar : DEFAULT_AVATAR"
              ></el-avatar>
              <p class="onLine" v-if="item.is_online == 1">
                <i></i> {{ $t("status_Online") }}
              </p>
              <p class="busy" v-else-if="item.is_online == 2">
                <i></i> {{ $t("status_Busy") }}
              </p>
              <p class="off" v-else><i></i> {{ $t("status_Offline") }}</p>
            </div>
            <div class="userFr">
              <p class="userName">
                {{ item.name_en | priorFormat(item.name_zh, LOCALE) }}
              </p>
              <p class="title">
                {{ item.job_title_en | priorFormat(item.job_title_zh, LOCALE) }}
              </p>
              <!-- <el-button type="primary" size="small" v-if="item.is_online == 1">{{
                $t("Negotiate_now")
              }}</el-button> -->
              <!-- <el-button
                type="primary"
                :disabled="isPreview"
                size="small"
                @click="invitation(item)"
                >{{ $t("meetingAppointment") }}</el-button
              > -->
              <chatBtn
                :oToId="item.one2one_id"
                :item="item"
                :disabled="isPreview"
              >
                <template slot="chatBtn">
                  <el-button
                    type="primary"
                    :disabled="isPreview"
                    size="small"
                    >{{ $t("meeting1v1") }}</el-button
                  >
                </template>
              </chatBtn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <dailyChatOrder
      @orderVisibleShow="orderVisibleShow"
      :orderVisible="orderVisible"
      :item="item"
    ></dailyChatOrder>

  </div>
</template>

<script>
import boothMixin from "./mixin";

import AliyunVideo from "~/baseComponents/AliyunVideo";
import plLivePlay from "~/baseComponents/plLivePlay";
import dailyChatOrder from "~/baseComponents/dailyChatOrder";
import chatBtn from "~/baseComponents/chat/1v1ChatBtn";

export default {
  mixins: [boothMixin],
  mounted() {
    this.companyId = this.$route.query.company_id;
    this.boothId = this.$route.query.booth_id;
    this.isPreview = this.$route.query.isPreview ? true : false;
    this.getMsg();
  },
  components: { AliyunVideo, dailyChatOrder, plLivePlay, chatBtn },
  data() {
    return {
      currentId: "",
      orderVisible: false,
      item: {},
      companyInfo: {},
      shipping_line: [],
      business_classification: [],
      companyId: "",
      collectionStatus: 1,
      userList: [],
      videoList: [],
      playAliyunId: "",
      playIndex: 0,
      hasLive: true,
      liveMsg: {},
      boothId: "",
      isPreview: false,
      isPlay: false,
      row: {},
    };
  },

  methods: {
    invitation(e) {
      this.handleUserMeetingStatus({
        notLogin: () => {
          //
          this.$router.push("/sign");
        },
        notEnroll: () => {
          this.$store.state.isApplyShow = true;
          this.$GLOBALEVENT.$emit("ISSHOWENROLL");
        },
        enrollWait: () => {
          this.$message({
            message:
              "您的报名信息正在审核中，请耐心等待审核通过后预约1v1洽谈！",
            type: "warning",
          });
        },
        enrollFail: () => {
          this.$message({
            message: "您好，您的报名信息未审核通过，您可以联系销售人员询问详情",
            type: "warning",
          });
        },
        enrollComplete: () => {
          this.orderVisible = true;
          this.item = {
            user_id: e.id,
            company_id: this.companyId,
          };
        },
      });
    },
    orderVisibleShow() {
      this.orderVisible = false;
    },
    videoPlayChange(index, id) {
      try {
        this.hasLive = false;
        this.playIndex = index;
        this.playAliyunId = this.videoList[this.playIndex].aliyun_id;
        if (!this.hasLive) {
          this.$log({
            type: 3,
            id: "Hall.business.booth.video",
            topic: "Hall.business",
            content: JSON.stringify({
              videoId: this.videoList[index].video_id,
            }),
          });
          this.$refs.AliyunVideo.changeVideo();
        }
      } catch (e) {}
    },
    gotoLive() {
      window.open(this.liveMsg.watch_url);
    },
    liveStart() {
      this.$refs.AliyunVideo.pauseVideo();
      this.isPlay = true;
      this.hasLive = true;
    },
    liveEnd() {
      this.isPlay = false;
      this.hasLive = false;
    },
    copyText(text, type) {
      if (type == 1) {
        this.$log({
          type: 1,
          id: "Hall.business.booth.copy",
          topic: "Hall.business",
          content: JSON.stringify({
            type: "Mobile",
          }),
        });
      }
      if (type == 2) {
        this.$log({
          type: 1,
          id: "Hall.business.booth.copy",
          topic: "Hall.business",
          content: JSON.stringify({
            type: "Email",
          }),
        });
      }
      if (!text) return;
      const input = document.createElement("textarea");
      document.body.appendChild(input);
      input.value = `${text}`;
      input.select();
      if (document.execCommand("copy")) {
        document.execCommand("copy");
      }
      document.body.removeChild(input);
      this.$message.success(this.$t("copySuccess"));
    },
    videoEnd() {
      if (this.playIndex < this.videoList.length - 1) {
        this.playIndex = this.playIndex + 1;
        this.playAliyunId = this.videoList[this.playIndex].aliyun_id;
      } else {
        this.playIndex = 0;
        this.playAliyunId = this.videoList[0].aliyun_id;
      }
      this.$refs.AliyunVideo.changeVideo();
    },
    async getMsg() {
      let data = await this.$store.dispatch("baseStore/getBoothCompanyInfo", {
        company_id: this.companyId,
        visit_company_id: this.USER_INFO.company_id,
        visit_user_id: this.USER_INFO.id,
        meeting_id: this.MEETING_ID,
      });
      if (data.success) {
        this.companyInfo = data.data;
        this.shipping_line = data.data.shipping_line;
        let arr = [];
        for (let key in data.data.business_classification) {
          data.data.business_classification[key].forEach((ee) => {
            arr.push(ee);
          });
        }

        this.business_classification = arr;
      }
      try {
        let follow = await this.$store.dispatch("baseStore/checkFollow", {
          user_id: this.USER_INFO.id,
          booth_id: this.boothId,
        });
        if (follow.success) {
          this.collectionStatus = follow.is_like ? 1 : 0;
        }
      } catch (error) {}
      let res = await this.$store.dispatch("baseStore/getBoothAbout", {
        meeting_booth_id: this.boothId,
        company_id: this.companyId,
        meeting_id: this.MEETING_ID,
      });
      this.userList = res.data.user_info_list;
      console.log(this.userList);
      this.videoList = res.data.video_info_list;

      let e = await this.$store.dispatch("baseStore/getBoothAgaen", {
        meeting_booth_id: this.boothId,
      });
      let msg = e.data;

      msg.forEach((ee) => {
        if (
          ee.start_time <= this.$moment().unix() &&
          this.$moment().unix() <= ee.end_time
        ) {
          this.hasLive = true;
          ee.channelId = ee.watch_url.split("/").pop();
          this.liveMsg = ee;
        }
      });
      if (this.videoList.length) {
        this.playAliyunId = this.videoList[0].aliyun_id;
        this.playIndex = 0;
      }
      setTimeout((res) => {
        var swiper = new Swiper(".swiper-container", {
          slidesPerView: 3,
          spaceBetween: 10,
          pagination: {
            el: ".swiper-pagination",
            clickable: true,
          },
        });
      }, 0);
      this.initComplete();
    },
    async collection(type) {
      if (!this.USER_INFO) {
        this.$router.push("/sign");
        return;
      }
      let data = await this.$store.dispatch("baseStore/handleCollectionNew", {
        meeting_id: this.MEETING_ID,
        company_id: this.companyId,
        like_type: type,
        user_id: this.USER_INFO.id,
        booth_id: this.boothId,
      });
      if (data.success) {
        this.collectionStatus = type;
      }
    },
    showCard(e) {
      this.row.user_id = e.id;
      let row = this.row;
      this.$GLOBALEVENT.$emit("OPENISUSERCARD", { show: true, row });
      // this.currentId=e.id
      // e.user_id = e.id;
      // let row = e;
      // row.company_id=this.companyId;
      // this.$refs.cardDialog.openCardDialog({
      //   followed_user_id: e.id,this.row
      //   emit_company_id:this.companyId,
      //   show: true,
      // })
      // return;
      // this.$GLOBALEVENT.$emit("OPENUSERCard", {
      //   show: true,
      //   row
      // });
    },
  },
};
</script>

<style scoped lang="less">
.marBottom {
  margin-bottom: 10px;
}
.el-icon-document-copy {
  color: #027fff;
  cursor: pointer;
}
.companyInfo {
  width: 100%;
  // height: 400px;
  background: #fff;
  padding: 30px;
  display: flex;
  .logo {
    margin-right: 30px;
  }
  .message {
    width: 1100px;
    .companyName {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name {
        word-break: break-all;
        display: flex;
        width: 900px;

        .namaTxt {
          width: 770px;
          font-weight: 900;
          font-size: 22px;
        }
        .boothId {
          padding-left: 15px;
          color: #dd9740;
          font-size: 14px;
        }
      }
    }
    .other {
      font-size: 14px;

      display: flex;
      align-items: center;
      margin: 10px 0;
      p {
        color: #999;
      }
      .mar {
        margin: 0 20px;
      }
    }
    .line {
      // display: flex;
      // align-items: center;
      margin: 10px 0;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      span {
        white-space: nowrap;
        padding-right: 20px;
        color: #1ea89d;
      }
      span:nth-child(1) {
        color: #999;
      }
    }
    .lastMsg {
      font-size: 14px;
      width: 100%;
      display: flex;
      align-items: center;
      margin: 10px 0;
      p {
        color: #999;
      }
      .mar {
        margin: 0 20px;
      }
      .companyAddress {
        width: 100%;
        overflow: hidden;

        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .userItem {
      display: flex;
      width: 33%;
      align-items: center;
      margin-top: 30px;
      .userFl {
        text-align: center;
        width: 80px;
        margin-right: 15px;
      }
    }
    .users {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      // justify-content: space-between;
      width: 100%;
      height: 170px;
      overflow-y: auto;
    }
    .userFr {
      width: 200px;
      .userName {
        font-size: 16px;
        font-weight: 900;
      }
      .title {
        font-size: 12px;
        color: #999;
        margin: 10px 0;
      }
    }
  }
}

.videoAndCompany {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 440px;
  background: #f2f2f2;
  position: relative;
  .video {
    background: #fff;
    width: 603px;
    .topBox {
      .liveThumb {
        width: 100%;
        height: 340px;
        margin-bottom: 10px;
        background: #f2f2f2;
        position: relative;
        .enterBtn {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .liveMsg {
        width: 603px;
        height: 440px;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
      }
      .isPlay {
        z-index: 99;
      }
    }
    .swiperBox {
      width: 100%;
      height: 87px;
      overflow: hidden;
      .swiper-container {
        width: 100%;
        height: 87px;
      }
      .swiper-wrapper {
        width: 100%;
        height: 87px;
      }
      .swiper-slide {
        width: 155px !important;
        height: 87px;
        cursor: pointer;
        position: relative;
      }
      .meak {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0px;
        left: 0px;
        background: rgba(0, 0, 0, 0.2);
        z-index: 100;
        color: #aaa;
        text-align: center;
        cursor: pointer;
        transition: all 0.15s;
        .el-icon-video-play {
          font-size: 30px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &:hover {
          background: rgba(0, 0, 0, 0.5);
        }
      }
      .coverImg {
        width: 155px;
        height: 87px;
      }
    }
  }
  .company {
    width: 697px;
    height: 440px;
    padding: 20px;
    background: #fff;
    .companyName {
      display: flex;

      justify-content: space-between;
      .name {
        width: 520px;
        word-break: break-all;
        display: flex;
        .namaTxt {
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
          overflow: hidden;
          width: 390px;
          font-weight: 900;
          font-size: 22px;
        }
        .boothId {
          padding-left: 15px;
          color: #dd9740;
          font-size: 14px;
        }
      }
    }
    .other {
      font-size: 14px;

      display: flex;
      align-items: center;
      margin: 10px 0;
      p {
        color: #999;
      }
      .mar {
        margin: 0 20px;
      }
    }
    .line {
      // display: flex;
      // align-items: center;
      margin: 10px 0;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      span {
        white-space: nowrap;
        padding-right: 20px;
        color: #1ea89d;
      }
      span:nth-child(1) {
        color: #999;
      }
    }
    .lastMsg {
      font-size: 14px;

      display: flex;
      align-items: center;
      margin: 10px 0;
      p {
        color: #999;
      }
      .mar {
        margin: 0 20px;
      }
      .companyAddress {
        width: 100%;
        overflow: hidden;

        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .userItem {
      display: flex;
      width: 300px;
      align-items: center;
      margin-top: 30px;
      .userFl {
        text-align: center;
        width: 80px;
        margin-right: 15px;
      }
    }
    .users {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 170px;
      overflow-y: auto;
    }
    .userFr {
      width: 200px;
      .userName {
        font-size: 16px;
        font-weight: 900;
      }
      .title {
        font-size: 12px;
        color: #999;
        margin: 10px 0;
      }
    }
  }
}
.onLine {
  display: flex;
  align-items: center;
  padding-left: 20px;
  i {
    color: #1ea89d;
    margin-right: 10px;
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #1ea89d;
  }
}
.busy {
  display: flex;
  align-items: center;
  padding-left: 20px;
  i {
    color: #e6a23c;
    margin-right: 10px;
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #e6a23c;
  }
}
.off {
  display: flex;
  align-items: center;
  padding-left: 20px;
  i {
    color: #909399;
    margin-right: 10px;
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: #909399;
  }
}
</style>
