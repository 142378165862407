<template>
  <div class="videoPlayComponents">
    <div
      class="prism-player"
      id="Ailyun_prismPlayer"
      v-show="hasAliyunId"
    ></div>
  </div>
</template>

<script>
export default {
  props: {
    AliyunId: {
      type: String,
      default: "",
    },
    autoplay:{    //自动播放
      type:Boolean,
      default:true,
    }
  },
  data() {
    return {
      hasAliyunId: true,
    };
  },
  mounted() {
    this.handlePlay();
  },
  methods: {
    async handlePlay() {
      if (!this.AliyunId) {
        return;
      }
      let authResult = await this.getAuth();
      this.initPlayer(authResult);
    },
    async changeVideo() {
      if (!this.player) {
        this.handlePlay();
        return;
      } else {
        let result = await this.getAuth();
        this.player.replayByVidAndPlayAuth(this.AliyunId, result.data.PlayAuth);
      }
    },
    async getAuth() {
      try {
        let result = await this.$store.dispatch(
          "baseStore/getPlayAuth",
          {
            aliyun_id: this.AliyunId,
          }
        );
        if (result.success) {
          return result;
        }
      } catch (e) {}
    },
    initPlayer(result) {
      this.player = new Aliplayer({
        id: "Ailyun_prismPlayer",
        width: "100%",
        height: "100%",
        autoplay: this.autoplay,
        language: this.IS_EN ? "en-us" : "zh-cn",
        vid: result&&result.data.VideoMeta.VideoId,
        playauth: result&&result.data.PlayAuth,
        cover: result&&result.data.VideoMeta.CoverURL,
      });
      this.player.on("play", () => {
        this.$emit("videoPlay");
      });
      this.player.on("ended", () => {
        this.$emit("videoEnd");
      });
    },
    pauseVideo() {
      if (this.player) {
        this.player.pause();
      }
    },
  },
  watch: {
    AliyunId: {
      handler(nv) {
        if (nv) {
          this.hasAliyunId = true;
          this.$nextTick(() => {
            this.changeVideo();
          });
        } else {
          this.hasAliyunId = false;
          this.player.pause();
        }
      },
    },
  },
  destroyed() {},
};
</script>

<style scoped lang='less'>
.videoPlayComponents {
  height: 100%;
  //   /deep/.videoPlayDialog {
  //     .el-dialog__body {
  //       padding: 0px;
  //       .playbox {
  //         .prism-player {
  //           height: 100%;
  //         }
  //       }
  //     }
  //   }
}
</style>