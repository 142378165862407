<template>
  <div class="agenda">
    <div class="agendItem">
      <el-dialog
        :title="$t('meetingAppointment')"
        :visible="orderVisible"
        width="700px"
        class="videoPlayDialog"
        @close="orderVisibleChange()"
      >
        <el-form :label-width="LOCALE=='zh'?'90px':'160px'">
          <el-form-item prop="date" class="time">
            <template slot="label">
              <div class="labelSlot">{{$t('appointmentData')}}</div>
            </template>
            <el-date-picker
              v-model="agendaForm.day"
              :picker-options="pickerOptions"
              type="date"
              size="small"
              :placeholder="$t('appointmentData')"
              @change="handleDay"
            ></el-date-picker>
          </el-form-item>

          <el-form-item prop="end_time" class="time">
            <template slot="label">
              <div class="labelSlot">{{$t('appointmentTime')}}</div>
            </template>
            <el-time-select
                v-model="agendaForm.start_time"
                :picker-options="{
                 start: '00:00',
                 step: '00:30',
                 end: '23:00',
                 minTime: date
                 }"
                size="small"
                format="HH:mm"
                value-format="HH:mm"
                :placeholder="$t('beginTime')"
                @change="handleStartTime"
            >
            </el-time-select>
            <span>-</span>
            <el-time-select
                size="small"
                :placeholder="$t('endTime')"
                v-model="agendaForm.end_time"
                format="HH:mm"
                value-format="HH:mm"
                :picker-options="{
                start: '00:30',
                step: '00:30',
                end: '23:30',
                minTime: agendaForm.start_time ? agendaForm.start_time : date
                }"
            >
            </el-time-select>
          </el-form-item>
          <el-form-item prop="send_postscript" :label="$t('notes')">
            <el-input
              type="textarea"
              :placeholder="$t('cargosremarksPlaceholder')"
              v-model="agendaForm.send_postscript"
              maxlength="200"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div></div>
          </el-form-item>
        </el-form>
        <div slot="footer">
          <el-button @click="orderVisibleChange()" size="small">{{$t('cancel')}}</el-button>
          <el-button
            type="primary"
            size="small"
            @click="confimAgenda"
            :loading="btnLoading"
            >{{$t('confirm')}}</el-button
          >
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import { secondFormat } from "~/basePlugins/filters";
  export default {
  props: {
    orderVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "预约（没用到）",
    },
    item:{
      type:Object
    }
  },
  data() {
    return {
      pickerOptions: {
        // disabledDate(time) {
        //   return time.getTime() <$store.state.baseStore.meetingInfo.start_time || time.getTime() > $store.state.baseStore.meetingInfo.end_time;
        // },
      },
      btnLoading: false,
      talkVisible: false,
      dialogTxt: "",
      agendaForm: {
        start_time: "",
        day: "",
        end_time: "",
        send_postscript: "",
      },
      pitchObj: {},
      date: ''
    };
  },
  mounted(){
    let start_time=this.$store.state.baseStore.meetingInfo.start_time*1000
    let end_time=this.$store.state.baseStore.meetingInfo.end_time*1000
    this.pickerOptions={
      disabledDate(time) {
        return time.getTime() <start_time || time.getTime() > end_time;
      },
    }
  },
  watch: {
    orderVisible() {
      if (!this.orderVisible) {
        this.btnLoading = false;
        this.agendaForm = {
          start_time: "",
          day: "",
          end_time: "",
          send_postscript: "",
        };
      } else {
        this.date = secondFormat(moment(new Date()).unix(), 'T')
      }
    },
  },
  methods: {
    hide() {},
    orderVisibleChange(val) {
      this.$emit("orderVisibleShow", val);
    },
    async confimAgenda() {  
      if (!this.agendaForm.day) {
        return false;
      }
      let startTime = null;
      let endTime = null;
      let nowTime = moment(new Date()).unix();
      let time = this.agendaForm.start_time;
      let hour = Number(time.split(":")[0]);
      let min = Number(time.split(":")[1]);
      let s = Number(hour * 3600) + Number(min * 60);
      startTime = (s + moment(this.agendaForm.day).unix()).valueOf();
      if (this.agendaForm.end_time) {
        let time = this.agendaForm.end_time;
        let hour = Number(time.split(":")[0]);
        let min = Number(time.split(":")[1]);
        let s = Number(hour * 3600) + Number(min * 60);
        endTime = (s + moment(this.agendaForm.day).unix()).valueOf();
      }
      let dayTime = moment(this.agendaForm.day).unix();
      let newTimeObj = {
        start_time: startTime ? startTime : "",
        end_time: endTime ? endTime : "",
        day: dayTime ? dayTime : "",
        send_postscript: this.agendaForm.send_postscript,
      };
      if (!newTimeObj.day) {
        this.$message.warning(this.$t('companyCatalog1v1Error4'));//日期不能为空
        return;
      } else if (!newTimeObj.start_time) {
        this.$message.warning(this.$t('companyCatalog1v1Error5'));//开始时间不能为空
        return;
      } else if (!newTimeObj.end_time) {
        this.$message.warning(this.$t('companyCatalog1v1Error6')); //结束时间不能为空
        return;
      } else if (newTimeObj.start_time && newTimeObj.start_time < nowTime) {
        this.$message.warning(this.$t('companyCatalog1v1Error7')) //"开始时间不能低于当前时间"
        return;
      } else if (newTimeObj.end_time - newTimeObj.start_time > 1801) {
        this.$message.warning("时间不能超过30分钟"); //不会发生
        return;
      } else if (
        newTimeObj.start_time &&
        newTimeObj.end_time &&
        newTimeObj.start_time >= newTimeObj.end_time
      ) {
        this.$message.warning("开始时间不能大于结束时间"); //不会发生
        return;
      } else {
        
        this.$emit("dataChange", newTimeObj);


        // day: 1615996800
        // end_time: 1615999800
        // send_postscript: "撒发射点"
        // start_time: 1615998000


        let params={
          start_time:newTimeObj.start_time,
          end_time:newTimeObj.end_time,
          send_user_id:this.USER_INFO.id,
          in_user_id:this.item.user_id,
          source:this.PJSource,
          daily_source:1,
          related_id:this.MEETING_ID
        }
        this.btnLoading = true;
        let checkStatus = await this.$store.dispatch("baseStore/checkChatStatus",params);
        let pp={
          start_time:newTimeObj.start_time,
          end_time:newTimeObj.end_time,
          send_user_id:this.USER_INFO.id,
          in_user_id:this.item.user_id,
          source:this.PJSource,
          send_company_id:this.USER_INFO.company_id,
          in_company_id:this.item.company_id,
          day:newTimeObj.day,
          send_postscript:newTimeObj.send_postscript,
          in_postscript:"",
          daily_source:1,
          related_id:this.MEETING_ID,
        }


        if(checkStatus && checkStatus.success){
            this.send1v1(pp)
        }else{
          if(checkStatus.errorcode==1028 || checkStatus.errorcode==1033){ // 此时间段已握手成功的有会议
               this.$confirm(this.$t('haved_meetingd_meeting'), this.$t('tipsTitle'), {//您此时间段已有会议，是否继续邀约?
                confirmButtonText: this.$t('Confirm'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning'
              }).then(() => {
                this.send1v1(pp)
              }).catch(() => {
                  this.btnLoading=false
                  this.orderVisibleChange()
              });
        }
        if(checkStatus.errorcode==1000){ //已存在邀约
            this.$confirm(this.$t('haved_same_meeting'), this.$t('tipsTitle'), {//您在此时间段已对该用户发过邀约
                confirmButtonText: this.$t('Confirm'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning'
              }).then(() => {
                this.btnLoading=false
                  this.orderVisibleChange()
              }).catch(() => {
                  this.btnLoading=false
                  this.orderVisibleChange()
              });
        }
      
      }

        
    }

    },
    async send1v1(params){
      let sendeve = await this.$store.dispatch("baseStore/sendDailyEve",params);
      if(sendeve && sendeve.success){
        this.$message.success(this.$t('orderSuccess'))
        this.$emit('eveSuccess',{success:true});
      }
      this.btnLoading=false
      this.orderVisibleChange()
    },
    loadingChange() {
      this.btnLoading = false;
    },
    handleDay(val) {
      let date = secondFormat(moment(val).unix(), 'LL')
      let current =  secondFormat(moment(new Date()).unix(), 'LL')
      if (date === current) {
        this.date = secondFormat(moment(new Date()).unix(), 'T')
      } else {
        this.date = ''
      }
    },
    handleStartTime(val) {
      let time = val
      let hour = Number(time.split(":")[0]);
      let min = Number(time.split(":")[1]);
      let s = Number(hour * 3600) + Number(min * 60);
      let startTime
      let day = moment().startOf('day').unix()
      startTime = s + day + 1800
      this.agendaForm.end_time = secondFormat(startTime, 'T')
    }
  },
  };
</script>
<style scoped lang="less">
.time {
  .el-date-editor {
    width: 50%;
  }
  /deep/ .el-form-item__content {
    display: flex;
  }
}
.labelSlot::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}

.mt {
  margin-top: 15px;
}
.bt {
  margin-bottom: 10px;
}
.notopen {
  text-indent: 2em !important;
}
/deep/.el-dialog__header {
  line-height: 30px;
}
/deep/.el-dialog__body {
  padding: 20px 30px 0 20px;
}
</style>
