<template>
  <div>
    <div class="business">
      <div class="titleBox">
        <p>{{ $t("businessProfile") }}</p>
      </div>
      <div class="businessData">
        <div class="clearfix content">
          <div class="fl title">{{ $t("BussinessMajor") }}</div>
          <div class="fl clearfix businessList">
            <div
              v-for="(it, index) in shippingList"
              :key="index"
              class="businessName fl"
            >
              #{{ it.name_en | priorFormat(it.name_cn, LOCALE) }}
            </div>
          </div>
        </div>
        <div class="clearfix content">
          <div class="fl title">{{ $t("companyGoodBusiness") }}</div>
          <div class="fl clearfix businessList">
            <div
              v-for="(it, index) in bussinessList"
              :key="index"
              class="businessName fl"
            >
              #{{ it.desc_en | priorFormat(it.desc_zh, LOCALE) }}
            </div>
          </div>
        </div>
        <div class="clearfix content">
          <div class="fl title">{{ $t("BussinessShipping") }}</div>
          <div class="fl clearfix businessList">
            <div
              v-for="(it, index) in companyList"
              :key="index"
              class="businessName fl"
            >
              #{{ it.code }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import boothMixin from "./mixin";

export default {
  mixins: [boothMixin],
  data() {
    return {
      bussinessList: [],
      shippingList: [],
      companyList: [],
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    async getList() {
      let id = this.$route.query.company_id || "";
      let params = {
        company_id: id,
        visit_company_id: this.USER_INFO.company_id,
        visit_user_id: this.USER_INFO.id,
      };
      try {
        let data = await this.$store.dispatch(
          "baseStore/getBoothCompanyInfo",
          params
        );
        if (data.success) {
          this.companyList = data.data.shipping_company;
          this.shippingList = data.data.shipping_line;
          if (data.data.business_classification) {
            this.filterbussiness(data.data.business_classification);
          }
        }
        this.initComplete();
      } catch (e) {
        console.log(e);
      }
    },
    filterbussiness(data) {
      let arr1 = [];
      Object.keys(data).forEach((it) => {
        data[it].forEach((its) => {
          arr1.push(its);
        });
      });
      this.bussinessList = arr1;
    },
  },
};
</script>

<style scoped lang="less">
.business {
  width: 943px;
  min-height: 200px;
  background: #fff;
  padding: 0 20px;
  margin-bottom: 12px;
  .titleBox {
    font-size: 16px;
    font-weight: 600;
    line-height: 60px;
    border-bottom: 1px solid #dcdfe6;
  }
  .businessData {
    margin: 20px 0px 0px;
    padding-bottom: 10px;
  }
  .content {
    padding-bottom: 16px;
    height: 45px;
    .title {
      min-width: 70px;
    }
    .businessList {
      max-width: 84%;
    }
  }
  .businessName {
    margin: 0 10px 10px;
    color: #1ea89d;
  }
}
</style>